<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->

      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        :class="`d-none d-lg-flex align-items-center p-0 ${brandConfig.loginBgColorClass}`"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-0"
        >
          <b-img
            fluid
            :src="brandConfig.loginBgImage"
            alt="Login"
            class=""
            style="max-height: 100vh;"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >

        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <logo
            :width="80"
            :height="80"
            class="mb-2"
          />

          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ brandConfig.loginWelcomeText }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ brandConfig.loginDescriptionText }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- phone -->
              <b-form-group
                label="Số điện thoại"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  vid="phone"
                  rules="required"
                >
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    autocomplete="off"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    name="phone"
                    placeholder="0868987355"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Mật khẩu</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="Mật khẩu"
                      autocomplete="on"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="d-flex justify-content-between mb-2">
                <!-- checkbox -->
                <!-- <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="isManager"
                    name="checkbox-1"
                  >
                    Đăng nhập tài khoản quản lý
                  </b-form-checkbox>

                </b-form-group> -->

                <div />
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small>Quên mật khẩu?</small>
                </b-link>
              </div>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || loggingIn"
              >
                <b-spinner
                  v-if="loggingIn"
                  small
                />
                {{ loggingIn ? 'Đang đăng nhập' : 'Đăng nhập' }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text
            v-if="appName !== 'TingTong'"
            class="text-center mt-2"
          >
            <span>Bạn chưa có tài khoản? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Đăng ký miễn phí</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { $themeConfig, $brandConfig } from '@themeConfig';
import Logo from '@/layouts/components/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue';
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt';
import { required } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
// eslint-disable-next-line import/no-cycle
import { getHomeRouteForLoggedInUser, updateUserAbilities } from '@/auth/utils';
import md5 from 'md5';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    Logo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      phone: '',
      password: '',
      isManager: false,

      // validation rules
      required,
      loggingIn: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    login() {
      this.loggingIn = true;
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              phone: this.phone,
              password: md5(this.password),
              role: null,
            })
            .then(response => {
              this.loggingIn = false;
              const { user, accessToken, refreshToken } = response.data;
              useJwt.setToken(accessToken);
              useJwt.setRefreshToken(refreshToken);
              localStorage.setItem('userData', JSON.stringify(user));

              useJwt.getPermissions().then(permissionResponse => {
                updateUserAbilities(permissionResponse.data);
                this.$ability.update(permissionResponse.data);
                this.$router
                  .replace(getHomeRouteForLoggedInUser())
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Xin chào ${user.name}`,
                        icon: 'UserIcon',
                        variant: 'success',
                        text: `Bạn đang đăng nhập với vai trò ${user.role ? user.role.name : 'chủ nhà'}!`,
                      },
                    });
                  });
                this.loggingIn = false;
              }).catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Thông báo',
                    text: error.response.data.message ? error.response.data.message : 'Tài khoản chưa được phân quyền',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
                this.loggingIn = false;
                localStorage.removeItem('userData');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('accessToken');
              });
            })
            .catch(error => {
              //
              if (error.response && error.response.data && error.response.data.errors) {
                this.$refs.loginForm.setErrors(error.response.data.errors);
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Thông báo',
                    text: error.response.data.message ? error.response.data.message : 'Lỗi hệ thống. Vui lòng chờ trong giây lát hoặc liên hệ hỗ trợ kỹ thuật để được trợ giúp kịp thời. Hotline liên hệ: 0868987355',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              }
              this.loggingIn = false;
            });
        }
      });
    },

  },

  setup() {
    const { appName, appLogoImage } = $themeConfig.app;

    const brandConfig = $brandConfig[appName.toLowerCase()];
    const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';

    return {
      appName,
      appLogoImage,
      brandConfig,
      isTingTong,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
